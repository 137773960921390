export const nb = {
  ads: {
    ad: 'Reklame',
    ads: 'Reklame',
    advertisement: 'Reklame',
    indicator: 'Reklame',
  },
  audio: {
    button_txt: 'Lydspor',
    menu_title: 'Lydspor',
  },
  cc: {
    button: 'CC',
    menu_title: 'Teksting',
  },
  core: {
    exit_fullscreen: 'Avslutt fullskjerm',
    fullscreen: 'Fullskjerm',
    mute: 'Skru av lyd',
    pause: 'Pause',
    play: 'Spill av',
    unmute: 'Skru på lyd',
    volume: 'Volum',
  },
  ovp: {
    starting_in: 'Sendingen starter om',
  },
  playlist: {
    cancel: 'Cancel',
    up_next: 'Neste video',
  },
  qsel: {
    menu_title: 'Kvalitet',
  },
  share: {
    clipboard_failure: 'Tilgang til utklippstavlen mislyktes',
    clipboard_success: 'Teksten er nå på utklippstavlen din',
    embed: 'Embed',
    link: 'Link',
    menu_title: 'Del',
  },
  speed: {
    menu_title: 'Hastighet',
  },
};
