/* global globalThis */
import { amediaFetch } from '@amedia/fetch';
import flowplayer from '@flowplayer/player';
import Hls from '@flowplayer/player/plugins/hls';
import Audio from '@flowplayer/player/plugins/audio';
import Ovp from '@flowplayer/player/plugins/ovp';
import Airplay from '@flowplayer/player/plugins/airplay';
import Chromecast from '@flowplayer/player/plugins/chromecast';
import Subtitles from '@flowplayer/player/plugins/subtitles';
import FloatOnScroll from '@flowplayer/player/plugins/float-on-scroll';
import Preview from '@flowplayer/player/plugins/preview';

// should have been @flowplayer/translations - but npm-package is broken
import { nb } from './translations.js';

Object.assign(flowplayer.i18n, { nb });
flowplayer(
  Hls,
  Ovp,
  Airplay,
  Chromecast,
  Subtitles,
  FloatOnScroll,
  Preview,
  Audio
);

const FLOWPLAYER_BASE_URL = 'https://cdn.flowplayer.com/releases/native';
const LOADER_NAMESPACE = '__flowplayer_loader__';

globalThis[LOADER_NAMESPACE] = globalThis[LOADER_NAMESPACE] || {};

const flowplayerVersion = process.env.FLOWPLAYER_VERSION;

// TODO: host from our domain instead; need to import/re-export '@flowplayer/player/flowplayer.css';
function loadCss() {
  const href = `${FLOWPLAYER_BASE_URL}/3/v${flowplayerVersion}/style/flowplayer.css`;
  const links = document.querySelectorAll(`link[href='${href}']`);

  if (links.length === 0) {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = href;
    link.setAttribute('data-status', 'loading');
    document.getElementsByTagName('head')[0].appendChild(link);
  }
}

const loadScript = (src, domRef) =>
  new Promise((resolve, reject) => {
    const el = document.createElement('script');
    el.src = src;
    el.onload = resolve;
    el.onerror = reject;
    domRef.appendChild(el);
  });

async function loadPlayerConfig({ playerId, mediaId }) {
  const res = await amediaFetch(
    `https://ljsp.lwcdn.com/web/public/native/config/${playerId}/${mediaId}`,
    {
      timeout: 2000,
    }
  );
  return await res.json();
}

function loadAdsScripts({ domRef, adsDisabled, debugLogging }) {
  if (adsDisabled) {
    return Promise.resolve();
  }
  const p = import('@flowplayer/player/plugins/ads');
  p.then((module) => flowplayer(module.default));
  if (!globalThis[LOADER_NAMESPACE].ads) {
    globalThis[LOADER_NAMESPACE].ads = p.then(() =>
      loadScript(
        `https://imasdk.googleapis.com/js/sdkloader/${
          debugLogging ? 'ima3_debug' : 'ima3'
        }.js`,
        domRef
      )
    );
  }

  return globalThis[LOADER_NAMESPACE].ads;
}

function isDivCollapsed() {
  let hasCheckedIfDivCollapsed = false;

  function checkIfDivCollapsed(resolve) {
    if (!hasCheckedIfDivCollapsed) {
      hasCheckedIfDivCollapsed = true;
      const element = document.createElement('div');
      element.innerHTML = '&nbsp;';
      element.className = 'ads adsbox ad-placement bottom-ad';
      element.style.position = 'absolute';
      element.style.left = '0px';
      element.style.top = '0px';
      element.style.width = '1px';
      element.style.height = '1px';
      document.body.appendChild(element);

      globalThis.setTimeout(() => {
        resolve(element.offsetHeight === 0);
        if (element.remove === undefined) {
          return element.domRefElement.removeChild(element);
        }
        return element.remove();
      }, 100);
    }
  }

  return new Promise((resolve) => {
    document.addEventListener('DOMContentLoaded', () =>
      checkIfDivCollapsed(resolve)
    );
    if (document.readyState === 'complete') {
      checkIfDivCollapsed(resolve);
    }
    globalThis.setTimeout(() => checkIfDivCollapsed(resolve), 1000);
  });
}

export default async function bootstrapFlowplayer({
  element,
  isAdsDisabled,
  isDebugLogging,
  playerId,
  mediaId,
}) {
  loadCss();
  const [adblocked, ovpConfig] = await Promise.all([
    isDivCollapsed(),
    loadPlayerConfig({ playerId, mediaId }).catch(() => ({})),
  ]);
  element.setAttribute('adblockchecked', '');
  await loadAdsScripts({
    domRef: element,
    adsDisabled: adblocked || isAdsDisabled,
    debugLogging: isDebugLogging,
  });
  return { ovpConfig, flowplayer };
}
